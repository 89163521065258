import React from 'react';

// リサーチパネル会員獲得用リターゲティング https://adingo-redmine.sjdc.voyage/issues/34108
export const RpRetargetingTagPresenter: React.FC = () => (
  <div
    dangerouslySetInnerHTML={{
      __html: `
<script type="text/javascript">
/* <![CDATA[ */
var google_conversion_id = 1023497431;
var google_conversion_label = "2n7oCM3ukAQQ16mF6AM";
var google_custom_params = window.google_tag_params;
var google_remarketing_only = true;
/* ]]> */
</script>
<script type="text/javascript" src="https://www.googleadservices.com/pagead/conversion.js"></script>
<noscript>
<div style="display:inline;">
<img height="1" width="1" style="border-style:none;" alt="" src="https://googleads.g.doubleclick.net/pagead/viewthroughconversion/1023497431/?value=0&amp;label=2n7oCM3ukAQQ16mF6AM&amp;guid=ON&amp;script=0"/>
</div>
</noscript>
    `,
    }}
  />
);
