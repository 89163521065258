import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from 'js/components/ui/Button/Button';
import {
  CategoryItem,
  Props as CategoryItemProps,
} from 'js/components/common/Category/CategoryItem';

const displayLimit = 6;

export type Props = {
  categories: CategoryItemProps[];
  isIcon?: boolean;
  isGrid?: boolean;
  isLimitedDisplay?: boolean;
};

export const CategoryListPresenter: React.FC<Props> = ({
  categories,
  isIcon = true,
  isGrid = true,
  isLimitedDisplay = false,
}) => {
  const [isHiddenCategoryItem, setIsHiddenCategoryItem] =
    useState(isLimitedDisplay);
  return (
    <div className={classNames('p_category', { 'is-grid': isGrid })}>
      <ul className="p_category__list">
        {categories.map((category, index) => (
          <CategoryItem
            {...category}
            key={category.id}
            isIcon={isIcon}
            isHidden={isHiddenCategoryItem && index >= displayLimit}
          />
        ))}
      </ul>
      {isHiddenCategoryItem && (
        <Button
          className="p_category__more c_more"
          callback={() => setIsHiddenCategoryItem(false)}
        >
          もっと見る
        </Button>
      )}
    </div>
  );
};
