import { useRequestImmutable } from 'js/hooks/useRequestWithDebugParams';
import { useUserInfo } from 'js/hooks/useUserInfo';
import { convertPublicationToAffiliateItem } from 'js/utils/affiliate';
import { DynamicDataResponse } from 'js/hooks/pages/useDynamicData/type';

export const useDynamicData = () => {
  const { data } =
    useRequestImmutable<DynamicDataResponse>('/api/top/dynamic/');
  const { userInfo, isLoading } = useUserInfo();

  return (
    data && {
      isLoading,
      ecnaviCampaigns: data.ecnaviCampaigns.map((campaign) => ({
        linkUrl: campaign.targetUrl,
        imageUrl: campaign.filename,
        title: campaign.name,
      })),
      identificationNotice: data.identificationNotice,
      firstenq: !data.fastenq
        ? undefined
        : {
            ...data.fastenq,
            sessionIdHash: data.ck,
          },
      newArrivalServiceItems: data.newArrivalServiceItems?.map((item) =>
        convertPublicationToAffiliateItem(item),
      ),
      campaigns: data.campaigns,
      ranking: data.ranking?.map((category) => ({
        ...category,
        items: category.items.map((item) =>
          convertPublicationToAffiliateItem(item),
        ),
      })),
      ck: data.ck,
      nickname: userInfo?.nickname,
      clock: data.clock,
      pushNotificationRequest: data.pushNotificationRequest,
    }
  );
};
