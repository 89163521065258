import React from 'react';
import classNames from 'classnames';

export type HeadingType =
  | 'screen'
  | 'section'
  | 'block'
  | 'level-1'
  | 'level-2'
  | 'level-3';
export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';

const defaultTagMap = {
  screen: 'h1',
  section: 'h2',
  block: 'h3',
  'level-1': 'h1',
  'level-2': 'h2',
  'level-3': 'h3',
};

const classNameMap = {
  screen: 'c_title_1',
  section: 'c_title_2',
  block: 'c_title_3',
  'level-1': 'c_heading-1',
  'level-2': 'c_heading-2',
  'level-3': 'c_heading-3',
};

export type Props = {
  children: React.ReactNode;
  type: HeadingType;
  tag?: HeadingTag;
  className?: string;
};

export const HeadingPresenter: React.FC<Props> = ({
  children,
  type,
  tag,
  className = '',
}) => {
  const defaultTag = defaultTagMap[type];
  const headingClassName = classNameMap[type];

  return (() =>
    React.createElement(
      tag || defaultTag,
      {
        className: classNames(headingClassName, className),
      },
      children,
    ))();
};
