import React from 'react';
import {
  HeadingPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Heading/presenter';

export type Props = PresenterProps;

export const Heading: React.FC<Props> = ({
  children,
  type,
  tag,
  className,
}) => (
  <HeadingPresenter type={type} tag={tag} className={className}>
    {children}
  </HeadingPresenter>
);
