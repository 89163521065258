import React from 'react';
import {
  CategoryItemPresenter,
  Props as PresenterProps,
} from 'js/components/common/Category/CategoryItem/presenter';

export type Props = PresenterProps;

export const CategoryItem: React.FC<Props> = ({
  id,
  name,
  href,
  isIcon,
  isHidden,
}) => (
  <CategoryItemPresenter
    id={id}
    name={name}
    href={href}
    isIcon={isIcon}
    isHidden={isHidden}
  />
);
