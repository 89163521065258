import jquery from  '!!raw-loader!public_html/js/modules/jquery/jquery_1_8_3';

if (typeof window !== 'undefined') {
  eval.call(null, jquery);
}

export const notification = {};

/**
 * @param wakeUp                     memcacheを使ったsleep状態を無視してwatcherを起動する true:sleepを無視してwatcherを起動する/false:sleepを無視しない
 * @param showButton                 メッセージにボタンを表示するかどうか true:表示/false:非表示
 * @param suffix
 * @param setProcessing
 */
notification.start = function(wakeUp, showButton, suffix, setProcessing = function(a){}) {
  notification.visible = false;
  notification.marginBox = 10;
  notification.wakeUp = wakeUp;
  notification.showButton = showButton;
  notification.gaTrackEventCategory = 'notification' + suffix;
  notification.queryString = location.search;
  notification.anchorString = location.hash;
  notification.setProcessing = setProcessing;
  notification.setup();
  notification.getMessage();
}

notification.pascalToSnake = function(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
}

notification.setup = function() {
  // simpleの閉じるボタン
  $(".close_area a.close").click(function () {
    if (!notification.visible) { return; }
    notification.visible = false;
    var box = $(this).closest(".notification_box");
    var top = box.offset().top;
    var height = box.height() + notification.marginBox;
    box.fadeOut(function() {
      $(".notification_box:visible").each(function(i, obj) {
        if ($(obj).offset().top < top) {
          $(obj).animate({bottom: '-=' + height}, 1000, 'easeOutCubic');
        }
      });
      notification.visible = true;
    });
    var name = $(this).closest(".notification_box").parent().attr('class');
    gaTrackEvent(this, notification.gaTrackEventCategory, 'close', name);
  });
  // simpleのリンククリックのga
  $('.notification_box a').click(function() {
    if (!notification.visible) { return; }
    notification.visible = false; // リンククリックは非表示にしないが、連続クリックを止める意味でvisible=falseにしている
    var name = $(this).closest(".notification_box").parent().attr('class');
    gaTrackEvent(this, notification.gaTrackEventCategory, 'click', name);
  });

  //richの閉じる際のクリックイベント
  function closeNotificationRich() {
    $(".notification_sp_area").fadeOut("slow");
    $("#notification_bg").animate({opacity:0}, function() {
      $("#notification_bg").height("0%");
    });
    setTimeout(notification.getMessage, 1000);
  }
  $(".notification_sp_area a.btn_close, .notification_sp_area a.btn_close_login").click(function() {
    if (!notification.visible) { return; }
    notification.visible = false;
    closeNotificationRich();

    var name = $(this).closest(".notification_sp_area").parent().attr('class');
    gaTrackEvent(this, notification.gaTrackEventCategory, 'close', name);
  });
  $("#notification_bg, .notification_close_button").click(function() {
    if (!notification.visible) { return; }
    notification.visible = false;
    closeNotificationRich();

    var name = $(".notification_sp_area:visible").parent().attr('class');
    gaTrackEvent(this, notification.gaTrackEventCategory, 'close_bg', name);
  });
  // richのリンククリックのga
  $('.notification_sp_area a.btn').click(function() {
    if (!notification.visible) { return; }
    notification.visible = false; // リンククリックは非表示にしないが、連続クリックを止める意味でvisible=falseにしている
    var name = $(this).closest(".notification_sp_area").parent().attr('class');
    gaTrackEvent(this, notification.gaTrackEventCategory, 'click', name);
  });

  $(window).resize(function() {
    //ボックスサイズ
    $(".notification_sp_area").css({
      top: ($(window).height() - $(".notification_sp_area").outerHeight()) / 2,
      left: ($(window).width() - $(".notification_sp_area").outerWidth()) / 2
    });
  });
  $(window).resize();

  if (!notification.showButton) {
    var message_box_button = $(".sprite_common.btn");
    message_box_button.hide();
  }
}

notification.parse = function(obj, params) {
  if (params == null) {
    return;
  }
  $.each(params, function(key, val) {
    if (key == 'urlquery') {
      var atag = obj.find("a.sprite_common.btn")[0];
      var query = atag.search;
      if (query == '') {
        query = val;
      } else {
        query += '&' + val
      }
      atag.search = query;
    } else if (key == 'appendClass') {
      $.each(val, function(k, v) {
        obj.find('.'+k).addClass(v);
      });
    } else {
      obj.find('.'+key).text(val);
    }
  });
}

notification.showMessage = function(messageData) {
  var lteIE9 = ($('#notification_lteIE9').length > 0);

  if (messageData.type == 'simple') {
    var message_height = 0;
    for (var messageName in  messageData.messages) {
      var message_name = notification.pascalToSnake(messageName);

      var message_box = $("div.notification_box_" + message_name + "_simple .notification_box");

      notification.parse(message_box, messageData.messages[messageName]);

      if (lteIE9) {
        message_box.fadeIn(1000);
      } else {
        message_box.show();
      }
      notification.visible = true;
      message_box.css("bottom", notification.marginBox + message_height);
      message_height += message_box.height() + notification.marginBox;

      gaTrackEvent(this, notification.gaTrackEventCategory, 'view', message_name + '_simple');
    }

    notification.setProcessing(false);
  }

  if (messageData.type == 'rich') {
    var message_name;
    var messageName;
    for (var name in  messageData.messages) {
      messageName = name;
      message_name = notification.pascalToSnake(name); // richは必ず1つ
    }

    var message_box = $("div.notification_box_" + message_name + "_rich .notification_sp_area");
    notification.parse(message_box, messageData.messages[messageName]);

    //オーバーレイ用のボックスを作成
    $("#notification_bg").css('opacity', 0);
    $("#notification_bg").height("100%");
    $("#notification_bg").animate({opacity:0.8}, function() {
      notification.visible = true;
    });
    //フェードエフェクト
    if (lteIE9) {
      message_box.fadeIn(1000);
    } else {
      message_box.fadeIn("fast");
    }
    gaTrackEvent(this, notification.gaTrackEventCategory, 'view', message_name + '_rich');
  }
}

notification.getMessage = function() {
  // 他の仕組みが動作しているときにはNotificationは動かさない
  var queryString = notification.queryString;
  var anchorString = notification.anchorString;
  if (queryString !== '' && !(/^\?(now|debug|travel|referrer)=/).test(queryString)) {
    return;
  }
  if (anchorString !== '') {
    return;
  }

  var params = {'timestamp':$("html").data("timestamp"), 'wakeUp':notification.wakeUp};
  $.each($('#notification_ajax_params').serializeArray(), function(i, val) {
    params[val['name']] = val['value'];
  });

  $.post('/api/contents/notification/message/', params)
    .done(function(messageData) {
      if (messageData.messages.length !== 0) {
        notification.showMessage(messageData);
      } else {
        notification.setProcessing(false);
      }
    });
}
