import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { notification } from 'js/api/contents/notification/notification';

export type Props = {
  ck?: string;
  nickname?: string;
  setProcessing: Dispatch<SetStateAction<boolean>>;
};

export const Notification: React.FC<Props> = ({
  ck,
  nickname,
  setProcessing,
}) => {
  useEffect(() => {
    if (ck && setProcessing) {
      notification.start(false, true, '', setProcessing);
    }
  }, [ck, setProcessing]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <form id="notification_ajax_params">
          <input type="hidden" name="ck" value={ck} />
        </form>
      </div>

      <div id="notification_bg"></div>

      {/* ▼ ノーティフィケイション rich*/}

      {/* Amazon承認回数加算 */}
      <div className="notification_box_amazon_purchase_amazon_rich">
        <div className="notification_sp_area">
          <div className="notification_sp_box fadeIn">
            <p className="sprite_common ribbon_congrats">
              おめでとうございます！
            </p>
            <p className="sprite_common bg_item item_star" />
            <div className="notification_sp_box_inner amazon_pts_area">
              <p className="sprite_cont amazon_pts">
                Amazonのお買い物承認回数が反映されたよ！
              </p>
            </div>
            <a
              href="https://ecnavi.jp/mypage/affiliate_history/"
              className="sprite_common btn btn_history"
            >
              履歴を確認する
            </a>
            <a href="#" className="sprite_common btn_close">
              閉じる
            </a>
          </div>
          {/*/. notification_sp_box*/}
        </div>
        {/*/. notification_sp_area*/}
      </div>

      {/* ショッピングポイント */}
      <div className="notification_box_shopping_point_rich">
        <div className="notification_sp_area">
          <div className="notification_sp_box fadeIn">
            <p className="sprite_common ribbon_congrats">
              おめでとうございます！
            </p>
            <p className="sprite_common bg_item item_point" />
            <div className="notification_sp_box_inner shopping_pts_area">
              <p className="sprite_cont shopping_pts">
                お買い物ポイントを獲得したよ！
              </p>
            </div>
            <a
              href="https://ecnavi.jp/mypage/point_history/?from=notification"
              className="sprite_common btn btn_point_history"
            >
              ポイント履歴を確認する
            </a>
            <a href="#" className="sprite_common btn_close">
              閉じる
            </a>
          </div>
          {/*/. notification_sp_box*/}
        </div>
        {/*/. notification_sp_area*/}
      </div>

      {/* 初アクセス */}
      <div className="notification_box_first_access_rich">
        <div className="notification_sp_area">
          <div className="notification_sp_box fadeIn">
            <p className="sprite_common bg_ticker_tape" />
            <p className="sprite_common ribbon_welcome">ECナビへようこそ！</p>
            <p className="sprite_common bg_item item_balloon" />
            <div className="notification_sp_box_inner first_access_area">
              <p className="sprite_common first_access" />
              <p className="nickname">
                「{nickname}」さん、
                <br />
                はじめまして！
              </p>
              <button
                className="notification_close_button c_button c_red c_large"
                type="button"
              >
                さっそくポイントを貯める
              </button>
            </div>
            <a href="#" className="sprite_common btn_close">
              閉じる
            </a>
          </div>
          {/*/. notification_sp_box*/}
        </div>
        {/*/. notification_sp_area*/}
      </div>

      {/* 初ポイント獲得 */}
      <div className="notification_box_first_point_get_rich">
        <div className="notification_sp_area">
          <div className="notification_sp_box fadeIn">
            <p className="sprite_common bg_ticker_tape" />
            <p className="sprite_common ribbon_congrats">
              おめでとうございます
            </p>
            <p className="sprite_common bg_item item_point" />
            <div className="notification_sp_box_inner first_point_area">
              <p className="sprite_cont first_point">初ポイントGET！</p>
              <p className="first_point_txt">
                引き続きどんどんポイントを貯めよう！
              </p>
            </div>
            <a
              href="https://ecnavi.jp/mypage/point_history/?from=notification"
              className="sprite_common btn btn_point_history"
            >
              ポイント履歴を確認する
            </a>
            <a href="#" className="sprite_common btn_close">
              閉じる
            </a>
          </div>
          {/*/. notification_sp_box*/}
        </div>
        {/*/. notification_sp_area*/}
      </div>

      {/* RPで1000pts.以上加算 */}
      <div className="notification_box_research_panel1000_rich">
        <div className="notification_sp_area">
          <div className="notification_sp_box fadeIn">
            <p className="sprite_common ribbon_congrats">
              おめでとうございます！
            </p>
            <p className="sprite_common bg_item item_point" />
            <div className="notification_sp_box_inner rp_pts_area">
              <p className="rp_pts">
                <span className="txt_rp">リサーチパネル</span>の
                <br />
                アンケートで
                <br />
                <span className="txt_rp_pts">
                  <strong className="point_value"></strong>ポイント
                </span>
                <br />
                獲得したよ！
              </p>
            </div>
            <a
              href="https://ecnavi.jp/mypage/point_history/?from=notification"
              className="sprite_common btn btn_point_history"
            >
              ポイント履歴を確認する
            </a>
            <a href="#" id="close" className="sprite_common btn_close">
              閉じる
            </a>
          </div>
          {/*/. notification_sp_box*/}
        </div>
        {/*/. notification_sp_area*/}
      </div>

      {/* ポイント交換可能 */}
      <div className="notification_box_point_exchange_rich">
        <div className="notification_sp_area">
          <div className="notification_sp_box fadeIn">
            <p className="sprite_common ribbon_congrats">
              おめでとうございます！
            </p>
            <p className="sprite_common bg_item item_point" />
            <div className="notification_sp_box_inner exchange_area">
              <p className="exchange">
                3,000ポイント貯まった！ポイント交換できるよ！
              </p>
            </div>
            <a
              href="https://ecnavi.jp/mypage/ex_select/"
              className="sprite_common btn btn_exchange"
            >
              ポイントを交換する
            </a>
            <a href="#" id="close" className="sprite_common btn_close">
              閉じる
            </a>
          </div>
          {/*/. notification_sp_box*/}
        </div>
        {/*/. notification_sp_area*/}
      </div>

      {/* グレードアップ確定 */}
      <div className="notification_box_ecmc_user_grade_up_rich">
        <div className="notification_sp_area">
          <div className="notification_sp_box fadeIn">
            <p className="sprite_common ribbon_congrats">
              おめでとうございます！
            </p>
            <p className="sprite_common bg_item item_star" />
            <div className="notification_sp_box_inner grade_up_area">
              <p className="sprite_cont grade_up">来月の予定グレードUP</p>
            </div>
            <div className="grade_2col clearfix">
              <p className="sprite_common grade_tag" />
              <p className="grade_txt">
                すごい！
                <br />
                <strong>
                  <span className="grade_name_ja"></span>会員
                </strong>
                だよ
              </p>
            </div>
            {/*/. grade_up_2col*/}
            <a
              href="https://ecnavi.jp/mypage/"
              className="sprite_common btn btn_grade"
            >
              グレードを確認する
            </a>
            <a href="#" id="close" className="sprite_common btn_close">
              閉じる
            </a>
          </div>
          {/*/. notification_sp_box*/}
        </div>
        {/*/. notification_sp_area*/}
      </div>

      {/* グレードダウン見込み */}
      <div className="notification_box_ecmc_user_grade_down_rich">
        <div className="notification_sp_area">
          <div className="notification_sp_box fadeIn">
            <p className="sprite_common ribbon_bad">あぶなーい</p>
            <p className="sprite_common bg_item item_sweat" />
            <div className="notification_sp_box_inner grade_down_area">
              <p className="sprite_cont grade_down">来月の予定グレード</p>
            </div>
            <div className="grade_2col clearfix">
              <p className="sprite_common grade_tag" />
              <p className="grade_txt">
                <strong>
                  <span className="grade_name_ja"></span>会員に
                  <br />
                  ダウンしちゃう
                </strong>
                かも！
              </p>
            </div>
            {/*/. grade_up_2col*/}
            <a
              href="https://ecnavi.jp/mypage/"
              className="sprite_common btn btn_grade"
            >
              グレードを確認する
            </a>
            <a href="#" id="close" className="sprite_common btn_close">
              閉じる
            </a>
          </div>
          {/*/. notification_sp_box*/}
        </div>
        {/*/. notification_sp_area*/}
      </div>

      {/* ▼ ノーティフィケイション simple */}

      {/* 連続アクセス */}
      <div className="notification_box_two_days_access_simple">
        <div className="notification_box slideUp">
          <div className="notification_header">
            <p className="nickname">{nickname}さん</p>
            <p className="close_area">
              <a className="close" href="#">
                ×
              </a>
            </p>
          </div>
          {/*/. notification_header*/}
          <div className="notification_cont_2col clearfix">
            <p className="icon" />
            <p className="text">
              いつも来てくれてありがとう！
              <br />
              今日もたくさんポイントGETしてね！
            </p>
          </div>
          {/*/.notification_cont_2col*/}
        </div>
        {/*/. notification_box*/}
      </div>

      {/* ResearchPanelPoint */}
      <div className="notification_box_research_panel100_simple">
        <div className="notification_box slideUp">
          <div className="notification_header">
            <p className="nickname">{nickname}さん</p>
            <p className="close_area">
              <a className="close" href="#">
                ×
              </a>
            </p>
          </div>
          {/*/. notification_header*/}
          <a href="https://ecnavi.jp/mypage/point_history/?from=notification">
            <div className="notification_cont_2col clearfix">
              <p className="icon" />
              <p className="text">
                やったね！
                <br />
                <span className="lnt">
                  リサーチパネルのアンケート回答
                </span>で
                <strong>
                  <span className="point_value"></span>pts.
                </strong>
                を獲得したよ！
              </p>
            </div>
            {/*/.notification_cont_2col*/}
          </a>
        </div>
        {/*/. notification_box*/}
      </div>

      {/* GradeBonus */}
      <div className="notification_box_grade_bonus_simple">
        <div className="notification_box slideUp">
          <div className="notification_header">
            <p className="nickname">{nickname}さん</p>
            <p className="close_area">
              <a className="close" href="#">
                ×
              </a>
            </p>
          </div>
          {/*/. notification_header*/}
          <a href="https://ecnavi.jp/mypage/point_history/?from=notification">
            <div className="notification_cont_2col clearfix">
              <p className="icon" />
              <p className="text">
                おめでとうございます！
                <br />
                <span className="lnt">グレードボーナスを獲得したよ！</span>
              </p>
            </div>
            {/*/.notification_cont_2col*/}
          </a>
        </div>
        {/*/. notification_box*/}
      </div>
    </>
  );
};
