import React from 'react';
import classNames from 'classnames';
import { Icon } from 'js/components/ui/Icon/Icon';
import { CategoryIconType } from 'js/types/icon';

export type Props = {
  id: number;
  name: string;
  href: string;
  isIcon?: boolean;
  isHidden?: boolean;
};

export const CategoryItemPresenter: React.FC<Props> = ({
  id,
  name,
  href,
  isHidden,
  isIcon = true,
}) => (
  <li className={classNames('p_category__item', { 'is-hidden': isHidden })}>
    <a
      className={classNames(
        'p_category__link',
        isIcon ? 'has-icon' : '',
        'c_reset',
        'c_blocklink',
      )}
      href={href}
    >
      {isIcon && (
        <Icon
          className="p_category__icon"
          icon={`category-${id}` as CategoryIconType}
        />
      )}
      {name}
    </a>
  </li>
);
