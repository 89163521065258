import { useState, useEffect } from 'react';

export const useScrollVisible = () => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollTop = () =>
    Math.max(
      window.scrollY,
      document.documentElement.scrollTop,
      document.body.scrollTop,
    );

  useEffect(() => {
    const appearancePosition = document.documentElement.clientHeight / 2;
    const onScroll = () => setIsVisible(scrollTop() > appearancePosition);
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return isVisible;
};
