import React from 'react';
import {
  CategoryListPresenter,
  Props as PresenterProps,
} from 'js/components/common/Category/CategoryList/presenter';

export type Props = PresenterProps;

export const CategoryList: React.FC<Props> = ({
  categories,
  isGrid,
  isIcon,
  isLimitedDisplay,
}) => (
  <CategoryListPresenter
    categories={categories}
    isGrid={isGrid}
    isIcon={isIcon}
    isLimitedDisplay={isLimitedDisplay}
  />
);
