import React from 'react';
import {
  TakeoverAdPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/TakeoverAd/presenter';

export type Props = PresenterProps;

export const TakeoverAd: React.FC<Props> = (props) => (
  <TakeoverAdPresenter {...props} />
);
